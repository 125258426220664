<template>
  <v-container fluid class="fill-height">
    <v-row
      justify="center"
      align="center"
      class="d-flex flex-column"
      v-if="this.$store.state.selectedProduct.isVoucher == false"
    >
      <div>
        <v-alert type="success" elevation="12" class="ma-4">
          <h3>{{ $t("redeemConfirm.confirmationMessage") }}</h3>
        </v-alert>
      </div>

      <div>
        <v-btn
          color="primary"
          :rounded="
            $appConfig.hasOwnProperty('button')
              ? $appConfig.button.rounded
              : false
          "
          @click="$router.push('home')"
          min-width="110px"
        >
          {{ $t("common.home") }}
        </v-btn>
      </div>
    </v-row>
    <v-row
      justify="center"
      align="center"
      class="d-flex flex-column"
      v-else-if="this.$root.redeemConfirming == false"
    >
      <div>
        <v-alert type="success" elevation="12" class="ma-4">
          <h3>{{ $t("redeemConfirm.confirmationVoucherMessage") }}</h3>
        </v-alert>
      </div>

      <div>
        <v-btn
          color="primary"
          :rounded="
            $appConfig.hasOwnProperty('button')
              ? $appConfig.button.rounded
              : false
          "
          @click="$router.push('home')"
          min-width="110px"
        >
          {{ $t("common.home") }}
        </v-btn>
      </div>
    </v-row>
    <v-row justify="center" align="center" class="d-flex flex-column" v-else>
      <v-card>
        <v-card-title>
          <!-- <v-icon large color="primary">
            mdi-lan-pending
          </v-icon> -->
          <lottie
            :options="defaultOptions"
            :height="100"
            :width="100"
            v-on:animCreated="handleAnimation"
          />
          {{ $t("redeemConfirm.ProcessingRequest") }}
        </v-card-title>
        <v-progress-linear indeterminate rounded height="6" color="primary">
        </v-progress-linear>
      </v-card>
    </v-row>
    <FlashMessage :position="'right top'"></FlashMessage>
  </v-container>
</template>

<script>
import Lottie from "../lottie.vue";
import * as animationData from "../assets/animated-server.json";

export default {
  components: {
    lottie: Lottie,
  },
  data() {
    return {
      ready: false,
      clientMessage: null,
      defaultOptions: { animationData: animationData.default },
      animationSpeed: 1,
    };
  },
  mounted() {
    this.CheckState();
    //this.$appInsights.trackPageView({ name: "RedeemConfirm" });
  },
  methods: {
    async CheckState() {
      if (this.$store.state.code == "") {
        this.$router.push("home");
      }
    },
    handleAnimation: function(anim) {
      this.anim = anim;
    },
  },
  computed: {
    backgroundDefault: function() {
      return {
        "background-image":
          "url(" + this.$appConfig.confirmRedemptionBackGroundImage + " )",
        "background-position": "center",
        "background-size": "cover",
      };
    },
    /*  isConfirming() {
      return this.$root.redeemConfirming;
    }, */
  },
};
</script>
